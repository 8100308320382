<template>
  <div class="exportList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="block">
          <div class="level">{{ item.expertLevelName || "--" }}</div>
          <div class="top">
            <div class="topleft">
              <img
                v-if="transforPhoto(item.photo)"
                :src="transforPhoto(item.photo)"
                alt=""
                class="pic"
              />
              <img v-else src="~@/assets/images/avtor.png" alt="" class="pic" />
            </div>
            <div class="topright">
              <div class="up">
                <div class="name">{{ item.name || "--" }}</div>
                <div class="type">{{ item.expertTypeName || "--" }}</div>
              </div>
              <div class="down">
                <van-icon name="phone-o" />
                <span class="phonelabel">电话</span>
                <span class="downphone">
                  <a :href="`tel:${item.mobileTel}`" @click.stop="() => {}">
                    {{ item.mobileTel || "--" }}</a
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="middle">
            <!-- <div class="item">
              <div class="itemlabel ">所属单位：</div>
              <div class="itemvalue overHidden">{{ item.orgName || "--" }}</div>
            </div> -->
            <div class="item">
              <div class="itemlabel">擅长工作类型：</div>
              <div class="itemvalue overHidden">
                {{ item.goodJobTypeName || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="itemlabel">擅长事故类型：</div>
              <div class="itemvalue overHidden">
                {{ item.goodAccidentTypeName || "--" }}
              </div>
            </div>
          </div>
        </div>
        <template #finished>
          <img
            v-if="list.length === 0 && finished"
            src="~@/assets/images/zwsj.png"
            alt="暂无数据"
            class="noData"
          />
          <div v-else>没有更多了</div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";
export default {
  name: "ExportList",
  data() {
    return {
      list: [],
      total: 0,
      query: {
        page: 1,
        size: 10
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.onRefresh();
  },
  methods: {
    transforPhoto(val) {
      if (!val) {
        return false;
      } else {
        // console.log(val.typeOf("string"), "------------------");
        return `${process.env.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${val}`;
      }
    },
    onLoad() {
      this.pageExperts(this.query);
    },
    onRefresh() {
      this.query.page = 1;
      this.pageExperts(this.query);
    },
    pageExperts(data) {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      api
        .pageExperts(data)
        .then(res => {
          if (res.list) {
            this.loadError = false;
            this.loading = false;
            if (this.query.page == 1) {
              this.list = res.list;
            } else {
              this.list = this.list.concat(res.list);
            }
            if (this.list.length >= res.total) {
              this.finished = true;
            } else {
              this.query.page++;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.loadError = true;
        });
    }
  }
};
</script>

<style lang="scss">
.exportList {
  position: relative;
  padding: 10px;
  padding-bottom: 60px;
  .block {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 12px;
    background: #fff;
    .level {
      position: absolute;
      right: 12px;
      top: 10px;
      padding: 2px;
      background: rgba(62, 141, 254, 1);
      box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
      border-radius: 2px 0px 0px 2px;
      font-size: 12px;
      color: #ffffff;
    }
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .topleft {
        display: flex;
        width: 50px;
        height: 50px;
        background: linear-gradient(
          180deg,
          rgba(54, 68, 95, 0.34) 0%,
          #ffffff 100%
        );
        box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
        border: 1px solid rgba(118, 194, 255, 0.25);
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .pic {
          width: 100%;
          height: 100%;
        }
      }
      .topright {
        margin-left: 12px;
        .up {
          display: flex;
          margin-bottom: 10px;
          .name {
            font-size: 16px;
            color: #333743;
            font-weight: 600;
            margin-right: 4px;
          }
          .type {
            background: rgba(65, 143, 253, 0.1);
            border-radius: 2px;
            color: #3d8cfe;
            font-size: 12px;
            padding: 2px;
          }
        }
        .down {
          font-size: 12px;
          .phonelabel {
            font-size: 14px;
            color: rgba(100, 116, 144, 0.7);
            text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
            margin-left: 2px;
          }
          .downphone {
            > a {
              color: #3e8dfe;
            }
            margin-left: 6px;
            color: #3d8cfe;
            text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
          }
        }
      }
    }
    .middle {
      padding: 12px;
      background: rgba(65, 143, 253, 0.05);
      border-radius: 8px;
      .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 12px;
        .itemlabel {
          color: rgba(100, 116, 144, 0.7);
        }
        .itemvalue {
          max-width: 200px;
          color: rgba(59, 66, 77, 0.7);
        }
      }
      .item:last-of-type {
        margin: 0;
      }
    }
  }
  .noData {
    width: 160px;
    margin-top: 100px;
  }
}
</style>
