<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-collapse v-model="activeNames" accordion @change="collapseSelect">
      <van-collapse-item
        v-for="item in list"
        :key="item.id"
        :title="item.name"
        :name="item.id"
      >
        <ul class="organ-list">
          <li
            v-for="item1 in item.children"
            :key="item1.id"
            class="organ-item"
            @click="goOrganDetail(item1)"
          >
            <div class="item-title">
              <div class="title-name">{{ item1.name || "--" }}</div>
              <span class="title-tips">{{
                `成员：${item1.memberNum || 0}人`
              }}</span>
            </div>
            <div class="content">
              <div class="content-item">
                <div class="item-label">责任人：</div>
                <div class="item-value">{{ item1.headPersonName || "--" }}</div>
              </div>
              <div class="content-item">
                <div class="item-label">电话：</div>
                <div class="item-value">
                  <a
                    :href="`tel:${item1.headPersonMobile}`"
                    @click.stop="() => {}"
                    >{{ item1.headPersonMobile || "--" }}</a
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </van-collapse-item>
    </van-collapse>
  </van-pull-refresh>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";

export default {
  name: "OrganizationList",
  data() {
    return {
      list: [],
      memberList: [],
      activeNames: "",
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      api.getEmergencyOrgData({ orgCode: this.userInfo.orgCode }).then(res => {
        this.list = [];
        if (res.length > 0) {
          this.mapOrganTree2list(res);
          this.activeNames = "";
        }
      });
    },
    goOrganDetail(item) {
      const parent = this.list.find(item1 => item1.id === item.parentId);
      this.$router.push({
        path: `/organDetail/${item.id}`,
        query: {
          parentName: parent.name,
          name: item.name,
          headPersonName: item.headPersonName,
          duty: item.duty
        }
      });
    },
    mapOrganTree2list(tree) {
      if (tree.length > 0) {
        tree.forEach(item => {
          this.list.push(item);
          if (item.children.length > 0) {
            this.mapOrganTree2list(item.children);
          }
        });
      }
    },
    collapseSelect(id) {
      if (id) {
        const data = this.list.find(item => item.id === id);
        data.children?.forEach((item2, index) => {
          api
            .queryEmergencyOrgPerson({
              groupId: item2.id,
              page: 1,
              size: 9999999
            })
            .then(res => {
              this.$set(item2, "memberNum", res?.total);
            });
        });
      }
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.van-collapse {
  font-family: PingFangSC-Medium, PingFang SC;
  margin-top: 10px;
  .van-collapse-item {
    font-size: 16px;
    font-weight: 600;
    color: #333743;
    text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
    margin-bottom: 10px;
    .organ-list {
      .organ-item {
        padding: 10px;
        background: rgba(65, 143, 253, 0.05);
        box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
        border-radius: 2px;
        font-size: 14px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-title {
          width: 100%;
          position: relative;
          padding-left: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          color: rgba(59, 66, 77, 0.7);
          text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
          .title-tips {
            font-size: 12px;
            padding: 4px;
            font-weight: 400;
            color: #fff;
            background: #3e8dfe;
            text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
            box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
            border-radius: 4px;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 3px;
            height: 100%;
            background: #3e8dfe;
            box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
          }
        }
        .content {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content-item {
            display: flex;
            align-items: center;
            .item-label {
              font-weight: 400;
              color: rgba(100, 116, 144, 0.7);
            }
            .item-value {
              > a {
                color: #3e8dfe;
              }
            }
          }
        }
      }
    }
  }
}
</style>
