<template>
  <div class="resourcecon">
    <van-nav-bar
      title="应急资源"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-tabs v-model="active" @change="changeTab">
      <van-tab title="应急物资"></van-tab>
      <van-tab title="专家信息"></van-tab>
      <van-tab title="应急组织"></van-tab>
      <van-tab title="应急救援队"></van-tab>
    </van-tabs>
    <div class="accidentBody">
      <Materials v-if="active == 0" />
      <ExportList v-else-if="active == 1" />
      <OrganizationList v-else-if="active == 2" />
      <RescueList v-else />
    </div>
  </div>
</template>

<script>
import MaterialsList from "./components/materialsList.vue";
import Materials from "./materialsDetail.vue";
import ExportList from "./components/exportList.vue";
import OrganizationList from "./components/OrganizationList";
import RescueList from "./components/rescueList.vue";
export default {
  name: "Resources",
  components: { Materials, ExportList, OrganizationList, RescueList },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    changeTab(val) {
      // console.log(this.active);
      // console.log(val);
    }
  }
};
</script>

<style lang="scss">
.resourcecon {
  .accidentBody {
    div {
      box-sizing: border-box;
    }
    background: #f7f8fa;
    height: calc(100vh - 58px);
    overflow: auto;
    padding-bottom: 24px;
  }
  .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar .van-nav-bar__title {
    color: #fff;
    font-size: 16px;
  }
  .van-tabs {
    font-size: 14px;
    font-weight: 400;
    color: #969799;
    border-top: 1px solid #f7f8fa;
  }
  .van-tab--active {
    color: #323233;
    font-weight: 500;
  }
}
</style>
