<template>
  <div class="materialsList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="block">
          <div class="con">
            <div class="top">
              <div class="topleft">
                <!-- item.enterprise.shortName -->
                {{ substrFour(item.materialName) }}
              </div>
              <div class="topright">
                <div class="up overHidden">
                  <!-- item.enterprise.enterpriseName -->
                  {{ dataFormat(item.orgName) }}
                </div>
                <span class="down"
                  >物资数量：{{
                    dataFormat(item.materialCount || item.materialNum)
                  }}</span
                >
              </div>
            </div>
            <div class="middle">
              <div class="item">
                <div class="itemlabel">
                  <div class="doit"></div>
                  <div class="label">责任人姓名</div>
                </div>
                <div class="person">
                  <!-- item.enterprise.enterpriseHead || -->
                  {{ dataFormat(item.headPersonName) }}
                </div>
              </div>
              <div class="item">
                <div class="itemlabel">
                  <div class="doit"></div>
                  <div class="label">责任人电话</div>
                </div>
                <div class="phone">
                  <!-- item.enterprise.enterpriseHeadTel || -->
                  <a
                    :href="`tel:${dataFormat(item.headPersonMobile)}`"
                    @click.stop="() => {}"
                  >
                    {{ dataFormat(item.headPersonMobile) }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="detail" @click="goDetail(item)">查看详情</div>
        </div>
        <template #finished>
          <img
            v-if="list.length === 0 && finished"
            src="~@/assets/images/zwsj.png"
            alt="暂无数据"
            class="noData"
          />
          <div v-else>没有更多了</div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";
export default {
  name: "MaterialsList",
  data() {
    return {
      list: [],
      total: 0,
      query: {},
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    if (this.userInfo.orgType == 2) {
      //企业
      this.query.orgCode = this.userInfo?.orgCode;
    }
    this.onRefresh();
  },
  methods: {
    substrFour(val) {
      if (val) {
        return val.slice(0, 4);
      } else {
        return "--";
      }
    },
    dataFormat(val) {
      return val ?? "--";
    },
    goDetail(data) {
      this.$router.push(`/materialsDetail/${data?.orgCode}`);
    },
    onLoad() {
      this.getMeterialsList(this.query);
    },
    onRefresh() {
      this.getMeterialsList(this.query);
    },
    getMeterialsList(data) {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      api
        .pageMaterial(data)
        .then(res => {
          if (res) {
            this.loadError = false;
            this.loading = false;
            this.list = res.list;
          }
          this.finished = true;
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.loadError = true;
        });
    }
  }
};
</script>

<style lang="scss">
.materialsList {
  position: relative;
  padding: 10px;
  padding-bottom: 60px;
  .block {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    .con {
      padding: 12px;
      background: #fff;
      .top {
        display: flex;
        align-items: center;
        .topleft {
          display: flex;
          width: 44px;
          height: 44px;
          padding: 0 4px;
          background: linear-gradient(133deg, #71a7fe 0%, #417bff 100%);
          border-radius: 12px;
          border: 1px solid rgba(190, 218, 255, 0.4);
          text-align: center;
          align-items: center;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
        }
        .topright {
          margin-left: 12px;
          .up {
            max-width: 270px;
            font-size: 18px;
            color: #3b4664;
            font-weight: 600;
            margin-bottom: 4px;
          }
          .down {
            background: rgba(65, 143, 253, 0.2);
            border-radius: 2px;
            color: #3d8cfe;
            font-size: 12px;
            padding: 2px;
          }
        }
      }
      .middle {
        display: flex;
        padding: 14px;
        background: linear-gradient(180deg, #ffffff 0%, #eff7ff 100%);
        border-radius: 8px;
        .item {
          width: 50%;
          .itemlabel {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .doit {
              width: 6px;
              height: 6px;
              background: #3e8dfe;
              box-shadow: 0px 2px 4px 0px rgba(62, 126, 245, 0.3);
              border-radius: 50%;
            }
            .label {
              font-size: 12px;
              color: rgba(100, 116, 144, 0.7);
              margin-left: 2px;
            }
          }
          .person {
            font-size: 14px;
            color: #445168;
          }
          .phone {
            > a {
              color: #3e8dfe;
            }
            font-size: 14px;
            color: #3e8dfe;
          }
        }
      }
    }

    .detail {
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #3e8dfe;
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
    }
  }
  .noData {
    width: 160px;
    margin-top: 100px;
  }
}
</style>
