<template>
  <div class="rescueList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="block">
          <div class="top">
            <div class="topleft">
              <div class="type">{{ item.specialAtName || "--" }}</div>
              <div class="name overHidden">{{ item.teamName || "--" }}</div>
            </div>
            <span v-if="item.teamSpecial === 0" class="rightsign">兼职</span>
            <span v-if="item.teamSpecial === 1" class="rightsign">专职</span>
          </div>
          <div class="middle">
            <div class="middleitem">
              <div class="middleitem-block">
                <div class="middleitem-label">队伍人数：</div>
                <div class="middleitem-value overHidden">
                  {{ dataFormat(item.teamSize) }}
                </div>
              </div>
              <div class="middleitem-block">
                <div class="middleitem-label">负责人姓名：</div>
                <div class="middleitem-value overHidden">
                  {{ item.headPersonName || "--" }}
                </div>
              </div>
            </div>
            <div class="middleitem">
              <div class="middleitem-block">
                <div class="middleitem-label">值班电话：</div>
                <div class="middleitem-phone overHidden">
                  <a :href="`tel:${item.dutyTel}`" @click.stop="() => {}">
                    {{ item.dutyTel || "--" }}</a
                  >
                </div>
              </div>
              <div class="middleitem-block">
                <div class="middleitem-label">负责人电话：</div>
                <div class="middleitem-phone overHidden">
                  <a
                    :href="`tel:${item.headPersonMobile}`"
                    @click.stop="() => {}"
                  >
                    {{ item.headPersonMobile || "--" }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="bottom" @click="look(item)">
            <div class="bottomleft">查看详情</div>
            <van-icon name="arrow" />
          </div>
        </div>
        <template #finished>
          <img
            v-if="list.length === 0 && finished"
            src="~@/assets/images/zwsj.png"
            alt="暂无数据"
            class="noData"
          />
          <div v-else>
            没有更多了
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
    <van-action-sheet v-model="show" title="应急救援队">
      <div class="detailcontent">
        <div class="detailtop">
          <div class="type overHidden">{{ form.specialAtName || "--" }}</div>
          <div class="name overHidden">
            {{ form.teamName || "--" }}
          </div>
          <span v-if="form.teamSpecial === 1" class="rightsign">专职</span>
          <span v-if="form.teamSpecial === 0" class="rightsign">兼职</span>
        </div>
        <div class="detailcon">
          <div class="detailconitem">
            <div class="detailconitemlabel">队伍人数：</div>
            <div class="detailconitemvalue">
              {{ dataFormat(form.teamName) }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">值班电话：</div>
            <div class="detailconitemvalue">
              <a :href="`tel:${form.dutyTel}`" @click.stop="() => {}">
                {{ form.dutyTel || "--" }}</a
              >
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">负责人姓名：</div>
            <div class="detailconitemvalue">
              {{ form.headPersonName || "--" }}
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">负责人电话：</div>
            <div class="detailconitemvalue">
              <a :href="`tel:${form.headPersonMobile}`" @click.stop="() => {}">
                {{ form.headPersonMobile || "--" }}</a
              >
            </div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">所属单位：</div>
            <div class="detailconitemvalue">{{ form.orgName || "--" }}</div>
          </div>
          <div class="detailconitem">
            <div class="detailconitemlabel">主要装备描述：</div>
            <div class="detailconitemphone">
              {{ form.equipmentDesc || "--" }}
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";
export default {
  name: "RescueList",
  data() {
    return {
      form: {},
      list: [],
      total: 0,
      show: false,
      query: {
        page: 1,
        size: 10
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.onRefresh();
  },
  methods: {
    dataFormat(val) {
      return val ?? "--";
    },
    look(data) {
      this.show = true;
      this.form = data;
    },
    onLoad() {
      this.pageRescueTeams(this.query);
    },
    onRefresh() {
      this.query.page = 1;
      this.pageRescueTeams(this.query);
    },
    pageRescueTeams(data) {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      api
        .pageRescueTeams(data)
        .then(res => {
          if (res.list) {
            this.loadError = false;
            this.loading = false;
            if (this.query.page == 1) {
              this.list = res.list;
            } else {
              this.list = this.list.concat(res.list);
            }
            if (this.list.length >= res.total) {
              this.finished = true;
            } else {
              this.query.page++;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.loadError = true;
        });
    }
  }
};
</script>

<style lang="scss">
.rescueList {
  position: relative;
  padding: 10px;
  padding-bottom: 60px;
  div {
    box-sizing: border-box;
  }
  .detailcontent {
    padding: 16px;
    .detailtop {
      display: flex;
      .type {
        max-width: 60px;
        padding: 2px;
        background: #3e8dfe;
        box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
        border-radius: 2px 0px 0px 2px;
        color: #ffffff;
        font-size: 12px;
        margin-right: 4px;
      }
      .name {
        max-width: 214px;
        font-size: 16px;
        color: #333743;
        font-weight: 600;
        margin-right: 12px;
      }
      .rightsign {
        font-size: 12px;
        padding: 2px;
        color: #3d8cfe;
        background: rgba(65, 143, 253, 0.2);
        border-radius: 2px;
      }
    }
    .detailcon {
      .detailconitem {
        display: flex;
        margin: 12px 0;
        font-size: 14px;
        .detailconitemlabel {
          color: #3b4664;
        }
        .detailconitemvalue {
          color: #3b4664;
          > a {
            color: #3e8dfe;
          }
        }
        .detailconitemphone {
          color: #3e8dfe;
        }
      }
    }
  }
  .block {
    padding: 12px 10px;
    background: #fff;
    margin-bottom: 10px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .topleft {
        display: flex;
        .type {
          padding: 2px;
          background: #3e8dfe;
          box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
          border-radius: 2px 0px 0px 2px;
          color: #ffffff;
          font-size: 12px;
          margin-right: 4px;
        }
        .name {
          max-width: 180px;
          font-size: 16px;
          color: #333743;
          font-weight: 600;
        }
      }
      .rightsign {
        font-size: 12px;
        padding: 2px;
        color: #3d8cfe;
        background: rgba(65, 143, 253, 0.2);
        border-radius: 2px;
      }
    }
    .middle {
      display: flex;
      justify-content: space-between;
      background: rgba(65, 143, 253, 0.05);
      box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
      border-radius: 2px;
      padding: 10px 12px;
      line-height: 26px;
      .middleitem {
        font-size: 14px;
        color: rgba(100, 116, 144, 0.7);
        text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
        font-weight: 400;
        .middleitem-block {
          display: flex;
          .middleitem-label {
            color: rgba(100, 116, 144, 0.7);
          }
          .middleitem-value {
            max-width: 50px;
            color: rgba(59, 66, 77, 0.7);
          }
          .middleitem-phone {
            > a {
              color: #3e8dfe;
            }
            color: rgba(62, 141, 254, 1);
          }
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      .bottomleft {
        color: #418ffd;
        font-size: 14px;
        text-shadow: 0px 4px 8px rgba(177, 200, 243, 0.15);
      }
    }
  }
  .noData {
    width: 160px;
    margin-top: 100px;
  }
}
</style>
